import { html } from 'lit';
import stylesBannerSquare from './styles/adn-banner-vertical-square-image';
import BaseBanner from './base-banner';

export default class AdnBannerVerticalGenericForAspectRatio extends BaseBanner {
  static styles = [stylesBannerSquare];

  protected type: string;

  constructor(type: string) {
    super();
    if (new.target === AdnBannerVerticalGenericForAspectRatio) {
      throw new Error('The AdnBannerVerticalGenericForAspectRatio class cannot be instantiated directly.');
    }
    this.type = type;
  }

  render() {
    const [click] = this.click_url as Array<TrackUrl>;

    return html`
      <a class="content-target" href=${click?.url} target=${this.target}>
        <div class="adn-container-${this.type}">
          <div class="adn-container-image">
            ${this.picture && this.renderImage('adn-base-image', this.picture)}
          </div>
          ${this.renderLogos()}
          ${this.renderContentVertical()}
        </div>
      </a>
    `;
  }
}
