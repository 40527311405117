import { css } from 'lit';

const stylesBannerVerticalSquare = css`
:host {
  --container-width: 0px; /* Default Value  */
  --dynamic-1n: calc(var(--container-width)*-1/278);
  --dynamic-33n: calc(var(--container-width)*-33.5/278);
  --dynamic-1: calc(var(--container-width)*1.5/278);
  --dynamic-2: calc(var(--container-width)*2/278);
  --dynamic-4: calc(var(--container-width)*4/278);
  --dynamic-8: calc(var(--container-width)*8/278);
  --dynamic-10: calc(var(--container-width)*10/278);
  --dynamic-11: calc(var(--container-width)*11/278);
  --dynamic-12: calc(var(--container-width)*12/278);
  --dynamic-14: calc(var(--container-width)*14/278);
  --dynamic-15: calc(var(--container-width)*15/278);
  --dynamic-16: calc(var(--container-width)*16/278);
  --dynamic-18: calc(var(--container-width)*18/278);
  --dynamic-20: calc(var(--container-width)*20/278);
  --dynamic-21: calc(var(--container-width)*21/278);
  --dynamic-24: calc(var(--container-width)*24/278);
  --dynamic-48: calc(var(--container-width)*48/278);
  --dynamic-50: calc(var(--container-width)*50/278);
  --dynamic-60: calc(var(--container-width)*60/278);
  --dynamic-67: calc(var(--container-width)*67/278);
  --dynamic-89: calc(var(--container-width)*89/278);
}

.content-target {
  text-decoration: none;
  cursor: pointer;
}

.adn-container-square {
  aspect-ratio: 278 / 449;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  .adn-container-image {
    height: 62%;
    position: relative;
    overflow: hidden;
  }

  .adn-base-image {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .adn-content-text {
    padding: var(--dynamic-50) var(--dynamic-21) 0 var(--dynamic-21);
  }

  .adn-text-volanta {
    margin: 0 0 var(--dynamic-8) 0;
  }

  .adn-cta {
    margin: var(--dynamic-8) 0 0 0;
  }

  .adn-text-legal {
    margin: var(--dynamic-8) 0 0 0;
  }
}

.position-logo {
  position: absolute;
  top: var(--dynamic-33n);
  margin: 0 0 0 var(--dynamic-21);
}

.adn-container-logo {
  position: relative;
}

.adn-logo {
  background-color: white;
  width: var(--dynamic-89);
  height: var(--dynamic-67);
  padding: var(--dynamic-8);
  box-sizing: border-box;
  border-radius: var(--dynamic-4);
  margin-left: var(--dynamic-16);
  object-fit: contain;
}

.adn-logo:first-child {
  margin: 0;
}

.adn-container-texts {
  height: 38%;
  margin-top: var(--dynamic-1n);
  border-radius: 0 0 var(--dynamic-4) var(--dynamic-4);
}

.adn-content-text {
  padding: var(--dynamic-60) var(--dynamic-21) 0 var(--dynamic-21);
}

.adn-content-text.without-logo {
  padding: var(--dynamic-48) var(--dynamic-21) 0 var(--dynamic-21);
}

.adn-text-volanta {
  font-size: var(--dynamic-10);
  line-height: var(--dynamic-12);
  font-weight: 600;
  letter-spacing: var(--dynamic-4);
  margin: 0 0 var(--dynamic-12) 0;
}

.adn-text-title {
  font-size: var(--dynamic-20);
  line-height: var(--dynamic-24);
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adn-cta {
  display: flex;
  margin: var(--dynamic-16) 0 0 0;
}

.adn-text-cta {
  font-size: var(--dynamic-14);
  font-weight: 400;
  line-height: var(--dynamic-18);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adn-cta-chevron {
  width: var(--dynamic-11);
  margin: var(--dynamic-1) 0 0 var(--dynamic-8);
  display: flex;
}

.adn-text-legal {
  font-size: var(--dynamic-10);
  line-height: var(--dynamic-15);
  font-weight: 400;
  margin: var(--dynamic-12) 0 0 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.adn-text-format {
  text-transform: uppercase;
}
`;

export default stylesBannerVerticalSquare;
